import React from "react"
import { useIntl } from "gatsby-plugin-intl"
import "../styles/components/FooterBanner.scss"

export default function FooterBanner() {
  const intl = useIntl()

  return (
    <section className="footer-banner">
      <p className="footer-banner__text">
        <span className="footer-banner__text-content">
          {intl.formatMessage({ id: "footer_text" })}
        </span>
      </p>
    </section>
  )
}
