import React, { useEffect } from "react"
import { useIntl } from "gatsby-plugin-intl"
import Layout from "../components/Layout.jsx"
import Hero from "../components/Hero.jsx"
import Seo from "../components/Seo"
import FooterBanner from "../components/FooterBanner.jsx"
import IconScroll from "../assets/images/icons/scroll-top-icon.svg"
import CourseOne from "../assets/images/course1.png"
import CourseTwo from "../assets/images/course2.png"
import CourseThree from "../assets/images/course3.png"
import WebinarsOne from "../assets/images/webinars1.png"
import WebinarsTwo from "../assets/images/webinars2.png"
import WebinarsThree from "../assets/images/webinars3.png"
import MarketEdu from "../assets/images/icons/market-edu.png"
import AnalyticsEdu from "../assets/images/icons/analytics-edu.png"
import CommerceEdu from "../assets/images/icons/commerce-edu.png"
import Ebook from "../assets/images/book.png"
import ArrowRight from "../assets/images/icons/arrow-right.png"

import "../styles/pages/Education.scss"

export default function Education() {
  const intl = useIntl()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <Layout>
      <Seo title={intl.formatMessage({ id: "education_title" })} />
      <Hero
        title={intl.formatMessage({ id: "education_title" })}
        text={intl.formatMessage({ id: "education_text" })}
      />
      <section className="education">
        <h3 className="education__title">
          {intl.formatMessage({ id: "education_subtitle" })}
        </h3>
        <div className="education__container">
          <article className="education__item">
            <img
              src={AnalyticsEdu}
              alt="Analytics"
              className="education__icon"
            />
            <h5 className="education__name">
              {intl.formatMessage({ id: "education_analysis_title" })}
            </h5>
            <p className="education__text">
              {intl.formatMessage({ id: "education_analysis_text" })}
            </p>
          </article>
          <article className="education__item">
            <img src={MarketEdu} alt="Market" className="education__icon" />
            <h5 className="education__name">
              {intl.formatMessage({ id: "education_market_title" })}
            </h5>
            <p className="education__text">
              {intl.formatMessage({ id: "education_market_text" })}
            </p>
          </article>
          <article className="education__item">
            <img src={CommerceEdu} alt="Commerce" className="education__icon" />
            <h5 className="education__name">
              {intl.formatMessage({ id: "education_trading_title" })}
            </h5>
            <p className="education__text">
              {intl.formatMessage({ id: "education_trading_text" })}
            </p>
          </article>
        </div>
      </section>
      <section className="advanced">
        <div className="advanced__container">
          <h1 className="advanced__title">
            {intl.formatMessage({ id: "education_subtitle_two" })}
          </h1>
        </div>
      </section>
      <section className="courses">
        <div className="courses__container">
          <div className="courses__grid">
            <article className="courses__item">
              <img src={CourseOne} alt="Course" className="courses__image" />
              <p className="courses__name">Forex</p>
            </article>
            <article className="courses__item">
              <img src={CourseTwo} alt="Course" className="courses__image" />
              <p className="courses__name">
                {intl.formatMessage({ id: "market_item_crypto" })}
              </p>
            </article>
            <article className="courses__item">
              <img src={CourseThree} alt="Course" className="courses__image" />
              <p className="courses__name">Commodities</p>
            </article>
          </div>
          {/* <div className="courses__action">
            <a
              href="https://education.Adrswap.com/login"
              target="_blank"
              rel="noreferrer"
              className="courses__button"
            >
              {intl.formatMessage({ id: "education_watch_courses" })}
              <img src={ArrowRight} alt="Arrow" className="courses__arrow" />
            </a>
          </div> */}
        </div>
        <div className="courses__container courses__container--bottom">
          <div className="courses__grid">
            <article className="courses__item">
              <img src={WebinarsOne} alt="Course" className="courses__image" />
              <p className="courses__name">
                {intl.formatMessage({ id: "education_webinars_one" })}
              </p>
            </article>
            <article className="courses__item">
              <img src={WebinarsTwo} alt="Course" className="courses__image" />
              <p className="courses__name">
                {intl.formatMessage({ id: "education_webinars_two" })}
              </p>
            </article>
            <article className="courses__item">
              <img
                src={WebinarsThree}
                alt="Course"
                className="courses__image"
              />
              <p className="courses__name">
                {intl.formatMessage({ id: "education_webinars_three" })}
              </p>
            </article>
          </div>
          {/* <div className="courses__action">
            <a
              href="https://education.Adrswap.com/login"
              target="_blank"
              rel="noreferrer"
              className="courses__button"
            >
              {intl.formatMessage({ id: "education_watch_webinars" })}
              <img src={ArrowRight} alt="Arrow" className="courses__arrow" />
            </a>
          </div> */}
        </div>
        <div className="courses__container courses__container--bottom">
          <div className="courses__grid courses__grid--ebook">
            <article className="courses__item">
              <div className="courses__ebook">
                <img src={Ebook} alt="Ebook" className="courses__ebook-image" />
                <p className="courses__ebook-name">
                  {intl.formatMessage({ id: "education_ebooks_one" })}
                </p>
              </div>
            </article>
            <article className="courses__item">
              <div className="courses__ebook">
                <img src={Ebook} alt="Ebook" className="courses__ebook-image" />
                <p className="courses__ebook-name">
                  {intl.formatMessage({ id: "education_ebooks_two" })}
                </p>
              </div>
            </article>
            <article className="courses__item">
              <div className="courses__ebook">
                <img src={Ebook} alt="Ebook" className="courses__ebook-image" />
                <p className="courses__ebook-name">
                  {intl.formatMessage({ id: "education_ebooks_three" })}
                </p>
              </div>
            </article>
          </div>
          {/* <div className="courses__action courses__action--ebooks">
            <a
              href="https://education.Adrswap.com/login"
              target="_blank"
              rel="noreferrer"
              className="courses__button"
            >
              {intl.formatMessage({ id: "education_watch_files" })}
              <img src={ArrowRight} alt="Arrow" className="courses__arrow" />
            </a>
          </div> */}
        </div>
      </section>
      <FooterBanner />
      <button id="scroll-btn" className="up__button" title="Scroll to top">
        <img src={IconScroll} alt="Up" className="up__image" />
      </button>
    </Layout>
  )
}
